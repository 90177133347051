export const messages = {
  addressLine1: "Address Line 1",
  addressLine1Required: "Address Line 1 field is required.",
  addressLine2: "Address Line 2",
  addressLine2Required: "Address Line 2 field is required.",
  city: "City",
  state: "State",
  country: "Country",
  stateRequired: "State field is required.",
  cityRequired: "City field is required.",
  pinCode: "Pin Code",
  pinCodeRequired: "Pin Code field is required.",
  somethingWentWrong: "Something went wrong, while saving the details",
  invalidPinCode: "Enter valid pin code",
  supplierName: "Supplier Name",
  supplierCode: "Supplier Code",
  branchName: "Branch Name",
  customerType: "Customer Type",
  discount: "Discount",
  profileSaveSuccess: "Profile information saved successfully",
  UPDATED_CUSTOMER_SUCCESS_MESSAGE: "Updated customer successfully!",
  CREATE_CUSTOMER_SUCCESS_MESSAGE: "Created customer successfully!",
  FILL_REQUIRED_FEIEDS_IN_BASIC_INFO:
    "Please fill required fields in basic details",
  FILL_REQUIRED_FEIEDS_IN_ADDRESSES: "Please fill required fields in addresses",
  FILL_REQUIRED_FEIEDS_IN_SUPPLIER_ASSOCIATIONS:
    "Please fill required fields in supplier associations",
  Error: "error",
  INVALID_MOBILE_NUMBER: "Invalid mobile number",
  NO_SUPPLIERS_AVAILBALE: "No suppliers available to select",
};

export enum queryEnum {
  GET_CUSTOMER_BY_MOBILE_NUMBER = "GET_CUSTOMER_BY_MOBILE_NUMBER",
  GET_SUPPLIER_INFO = "GET_SUPPLIER_INFO",
  GET_CITIES_AND_STATES = "GET_CITIES_AND_STATES",
  GET_MOBILE_CONFIG_KEY = "MOBILE_CONFIG",
  GET_ALL_SUPPLIERS = "GET_ALL_SUPPLIERS",
  BRANDS_INFO = "BRANDS_INFO",
  PART_CATEGORY_INFO = "PART_CATEGORY_INFO",
}
