import { ChangeEvent, Key, useEffect, useState } from "react";
import {
  Flex,
  FormControl,
  Box,
  InputGroup,
  InputLeftAddon,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Input,
  Switch,
  Select,
  HStack,
  Button,
  IconButton,
} from "@chakra-ui/react";
import GaraazFormLabel from "../../../components/GarrazFormLabel";
import GaraazBasicInfoStack from "../../../components/GaraazBasicInfoStack";
import {
  validateEmail,
  validateMobileNumber,
} from "../../../utils/validationHelpers";
import { useCreateCustomerByRole, useGetAllSuppliers } from "../queryHooks";
import AddressDetailsFormCustomer from "./AddressDetailsFormCustomer";
import useNotification from "../../../utils/useNotification";
import globalConstant from "../../../utils/constants";
import { useGetMobileConfig } from "../queryHooks";
import { messages } from "../constants";
import SupplierAssociationInfo from "./SupplierAssociationInfo";
import { Customer, SupplierAssociation } from "../types";
import PageScaffold from "../../../components/PageScaffold";
import { pageTitles } from "../../../utils/paths";
import { BiSave } from "react-icons/bi";
import { useUserDetailsContext } from "../../../Contexts/userDetailsContext";
import getUserRole from "../../../utils/getUserRole";

const AddNewCustomer = () => {
  const userDetails = useUserDetailsContext();
  const [customerProfileData, setCustomerProfileData] = useState<
    Customer | undefined
  >({
    name: "",
    mobileNumber: "",
    email: "",
    pan: "",
    gstin: "",
    isXpress: true,
    type: "WORKSHOP",
    supplierAssociations: [],
    creditLimit: 0,
    isBlocked: false,
  });
  const [customerAddressData, setCustomerAddressData] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    countryCode: "+91",
    pinCode: "",
  });

  const toast = useNotification();

  const [touchedFields, setTouchedFields] = useState<{
    [key: string]: boolean;
  }>({});

  const handleFieldBlur = (field: any) => {
    setTouchedFields((prevTouchedFields) => ({
      ...prevTouchedFields,
      [field]: true,
    }));
  };

  const isFieldTouched = (field: any) => {
    return touchedFields[field];
  };

  function handleChangeAddressInputs(name: string, value: string) {
    setCustomerAddressData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    setCustomerProfileData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const { data: allSuppliers } = useGetAllSuppliers();

  const {
    mutateAsync: createCustomer,

    isSuccess,
  } = useCreateCustomerByRole();

  const { data: customerTypes } = useGetMobileConfig();

  useEffect(() => {
    setCustomerProfileData({
      name: "",
      mobileNumber: "",
      email: "",
      pan: "",
      gstin: "",
      isXpress: true,
      type: "WORKSHOP",
      supplierAssociations: [],
      creditLimit: 0,
      isBlocked: false,
    });
    setCustomerAddressData({
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      countryCode: "+91",
      pinCode: "",
    });
    setTouchedFields({});
  }, [isSuccess]);

  const handleSaveButtonClick = async () => {
    if (
      !customerProfileData?.name ||
      !customerProfileData?.mobileNumber ||
      !customerProfileData?.type ||
      (!customerProfileData?.creditLimit &&
        customerProfileData?.creditLimit !== 0) ||
      customerProfileData?.creditLimit < 0
    ) {
      toast({
        description: messages.FILL_REQUIRED_FEIEDS_IN_BASIC_INFO,
        status: globalConstant.Error,
      });
      return;
    }

    if (
      !customerAddressData.addressLine1 ||
      !customerAddressData.city ||
      !customerAddressData.state ||
      !customerAddressData.pinCode
    ) {
      toast({
        description: messages.FILL_REQUIRED_FEIEDS_IN_ADDRESSES,
        status: globalConstant.Error,
      });
      return;
    }

    if (
      !!customerProfileData.supplierAssociations.length &&
      !customerProfileData.supplierAssociations.every((sa) => sa.supplierId)
    ) {
      toast({
        description: messages.FILL_REQUIRED_FEIEDS_IN_SUPPLIER_ASSOCIATIONS,
        status: globalConstant.Error,
      });
      return;
    }

    if (!validateMobileNumber(customerProfileData.mobileNumber)) {
      toast({
        description: messages.INVALID_MOBILE_NUMBER,
        status: globalConstant.Error,
      });
      return;
    }

    const customerBasicData: {
      mobileNumber: string;
      name: string;
      email?: string;
      pan?: string;
      gstin?: string;
      type?: string;
      supplierAssociations: {
        supplierId: string;
        supplierBranchId?: string;
        supplierCode?: string;
      }[];
      creditLimit: number | undefined;
      isBlocked: boolean | undefined;
    } = {
      ...customerProfileData,
      mobileNumber: `91${customerProfileData.mobileNumber}`,
      supplierAssociations: customerProfileData.supplierAssociations.map(
        (sa: any) => {
          const {
            supplierId,
            supplierBranchId,
            supplierCode,
            customerType,
            discount,
          } = sa;
          return {
            supplierId,
            ...(supplierBranchId && { supplierBranchId }),
            ...(supplierCode && { supplierCode }),
            ...(customerType && { customerType }),
            ...(discount && { discount }),
          };
        }
      ),
      creditLimit:
        getUserRole(userDetails) === "SUPER_ADMIN"
          ? customerProfileData.creditLimit
          : undefined,
      isBlocked:
        getUserRole(userDetails) === "SUPER_ADMIN"
          ? customerProfileData.isBlocked
          : undefined,
    };

    if (customerProfileData.email === "") {
      delete customerBasicData.email;
    }

    if (customerProfileData.gstin === "") {
      delete customerBasicData.gstin;
    }
    if (customerProfileData.pan === "") {
      delete customerBasicData.pan;
    }
    if (customerProfileData.type === "") {
      delete customerBasicData.type;
    }
    await createCustomer({ customerBasicData, customerAddressData });
  };

  const handleDeleteSupplierAssociation = async (index: number) => {
    setCustomerProfileData((prevCustomerData) => {
      if (!prevCustomerData) {
        return prevCustomerData;
      }

      const updatedAssociations = [...prevCustomerData.supplierAssociations];
      updatedAssociations.splice(index, 1);

      return {
        ...prevCustomerData,
        supplierAssociations: updatedAssociations,
      };
    });
  };

  const handleUpdateSupplierAssociation = (
    index: number,
    field: string,
    value: string
  ) => {
    setCustomerProfileData((prevCustomerData) => {
      if (!prevCustomerData) {
        return prevCustomerData;
      }

      const updatedAssociations = [...prevCustomerData.supplierAssociations];
      updatedAssociations[index] = {
        ...updatedAssociations[index],
        [field]: value,
      };

      return {
        ...prevCustomerData,
        supplierAssociations: updatedAssociations,
      };
    });
  };

  const availableSuppliers = allSuppliers?.filter((supplier: any) => {
    return !customerProfileData?.supplierAssociations.some(
      (association) => association.supplierId === supplier._id
    );
  });

  const handleAddSupplierAssociation = () => {
    if (!availableSuppliers.length) {
      toast({
        description: messages.NO_SUPPLIERS_AVAILBALE,
        status: messages.Error,
      });
      return;
    }

    if (
      customerProfileData?.supplierAssociations.some(
        (association) => !association.supplierId
      )
    ) {
      toast({
        description: messages.FILL_REQUIRED_FEIEDS_IN_SUPPLIER_ASSOCIATIONS,
        status: messages.Error,
      });
      return;
    }

    const newSupplierAssociation = {
      supplierId: "",
      supplierBranchId: "",
      supplierCode: "",
    };

    setCustomerProfileData((prevCustomerData: any) => {
      if (!prevCustomerData) {
        return prevCustomerData;
      }

      const updatedAssociations = [
        ...prevCustomerData.supplierAssociations,
        newSupplierAssociation,
      ];

      return {
        ...prevCustomerData,
        supplierAssociations: updatedAssociations,
      };
    });
  };

  return (
    <PageScaffold
      title={pageTitles.customers}
      hasButton={true}
      buttonText={
        <IconButton
          position={"fixed"}
          right={4}
          aria-label="saveBtn"
          size="md"
          color={"white"}
          icon={<BiSave size={"1.5rem"} />}
          onClick={handleSaveButtonClick}
        />
      }
    >
      <GaraazBasicInfoStack />
      <Box p={4} width={"100%"}>
        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl width={"25%"} id="name" flex={1}>
            <GaraazFormLabel text="Name" isRequired={true} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl flex={1}>
              <Input
                type="text"
                placeholder="Enter name"
                name="name"
                value={customerProfileData?.name}
                onChange={handleInputChange}
                onBlur={() => handleFieldBlur("name")}
              />
              {isFieldTouched("name") && !customerProfileData?.name?.length && (
                <Text color="red" fontSize={"sm"}>
                  Enter a valid name
                </Text>
              )}
            </FormControl>
          </Flex>
        </Flex>

        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl width={"25%"} id="mobileNumber" flex={1}>
            <GaraazFormLabel text="Mobile Number" isRequired={true} />
          </FormControl>

          <FormControl ml={4} width={"75%"}>
            <InputGroup>
              <InputLeftAddon bg="gray.100" children="+91" />
              <Input
                type="number"
                placeholder="Enter mobile number"
                width={"100%"}
                name="mobileNumber"
                value={customerProfileData?.mobileNumber}
                maxLength={10}
                minLength={10}
                onChange={handleInputChange}
                onBlur={() => handleFieldBlur("mobileNumber")}
              />
            </InputGroup>
            {isFieldTouched("mobileNumber") &&
              !!customerProfileData?.mobileNumber?.length &&
              (!validateMobileNumber(customerProfileData?.mobileNumber) ||
                customerProfileData?.mobileNumber?.length !== 10) && (
                <Text color="red" fontSize={"sm"}>
                  Enter a valid mobile number
                </Text>
              )}
          </FormControl>
        </Flex>

        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl width={"25%"} id="email" flex={1}>
            <GaraazFormLabel text="email" isRequired={false} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl>
              <Input
                type="email"
                placeholder="Enter email"
                width={"100%"}
                name="email"
                value={customerProfileData?.email}
                onChange={handleInputChange}
                onBlur={() => handleFieldBlur("email")}
              />
              {isFieldTouched("email") &&
                !!customerProfileData?.email?.length &&
                !validateEmail(customerProfileData?.email) && (
                  <Text color="red" fontSize={"sm"}>
                    Enter a valid email address
                  </Text>
                )}
            </FormControl>
          </Flex>
        </Flex>

        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl width={"25%"} id="pan" flex={1}>
            <GaraazFormLabel text="pan" isRequired={false} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl>
              <Input
                type="text"
                placeholder="Enter PAN number"
                width={"100%"}
                name="pan"
                value={customerProfileData?.pan}
                onChange={handleInputChange}
                onBlur={() => handleFieldBlur("pan")}
              />

              {isFieldTouched("pan") &&
                !!customerProfileData?.pan?.length &&
                customerProfileData?.pan?.length !== 10 && (
                  <Text color="red" fontSize={"sm"}>
                    Enter a valid PAN
                  </Text>
                )}
            </FormControl>
          </Flex>
        </Flex>

        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl width={"25%"} id="gstin" flex={1}>
            <GaraazFormLabel text="Gst" isRequired={false} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl>
              <Input
                type="text"
                placeholder="Enter GST number"
                width={"100%"}
                name="gstin"
                value={customerProfileData?.gstin}
                onChange={handleInputChange}
                onBlur={() => handleFieldBlur("gstin")}
              />
              {isFieldTouched("gstin") &&
                !!customerProfileData?.gstin?.length &&
                customerProfileData.gstin.length !== 15 && (
                  <Text color="red" fontSize={"sm"}>
                    Enter a valid GST
                  </Text>
                )}
            </FormControl>
          </Flex>
        </Flex>

        <Flex alignItems="center" justify="space-between" width={"70%"} mb={4}>
          <FormControl width={"25%"} id="gstin" flex={1}>
            <GaraazFormLabel text="type" isRequired={true} />
          </FormControl>
          <Flex width={"75%"}>
            <Select
              placeholder={"Select customer type"}
              name="type"
              defaultValue="WORKSHOP"
              value={customerProfileData?.type}
              onChange={(e) => {
                setCustomerProfileData((prevData: any) => ({
                  ...prevData,
                  type: e.target.value,
                }));
              }}
            >
              {customerTypes?.customer_types?.map((type: any, index: any) => (
                <option value={type} key={`${type}-${index}`}>
                  {type}
                </option>
              ))}
            </Select>
          </Flex>
        </Flex>

        <Flex alignItems="center" justify="space-between" width={"70%"} mb={4}>
          <FormControl width={"25%"} id="isXpress" flex={1}>
            <GaraazFormLabel text="Xpress" isRequired={false} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl>
              <Switch
                isChecked={customerProfileData?.isXpress}
                isReadOnly={true}
                size="lg"
              />
            </FormControl>
          </Flex>
        </Flex>
        <Flex alignItems="center" justify="space-between" width={"70%"} mb={4}>
          <FormControl width={"25%"} id="creditLimit" flex={1}>
            <GaraazFormLabel text="Credit Limit" isRequired={true} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl>
              <Input
                isReadOnly={!(getUserRole(userDetails) === "SUPER_ADMIN")}
                type="number"
                placeholder="Enter Credit Limit"
                width={"100%"}
                name="creditLimit"
                value={customerProfileData?.creditLimit}
                onChange={handleInputChange}
                onBlur={() => handleFieldBlur("creditLimit")}
              />
              {isFieldTouched("creditLimit") &&
                customerProfileData?.creditLimit !== 0 &&
                (!customerProfileData?.creditLimit ||
                  customerProfileData?.creditLimit < 0) && (
                  <Text color="red" fontSize={"sm"}>
                    Enter a Valid Credit Limit
                  </Text>
                )}
            </FormControl>
          </Flex>
        </Flex>
        <Flex alignItems="center" justify="space-between" width={"70%"}>
          <FormControl width={"25%"} id="isBlocked" flex={1}>
            <GaraazFormLabel text="Blocked" isRequired={true} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl>
              <Switch
                isChecked={customerProfileData?.isBlocked}
                isReadOnly={!(getUserRole(userDetails) === "SUPER_ADMIN")}
                onChange={(e) => {
                  setCustomerProfileData((prevState: any) => {
                    return {
                      ...prevState,
                      isBlocked: e.target.checked,
                    };
                  });
                }}
                size="lg"
              />
            </FormControl>
          </Flex>
        </Flex>
      </Box>

      <Tabs isLazy={true} position="relative" variant="styled">
        <TabList borderBottom={"1px solid lightgrey"}>
          <Tab
            _selected={{ border: "none", outline: "none", boxShadow: "none" }}
          >
            Address
          </Tab>
          <Tab
            _selected={{ border: "none", outline: "none", boxShadow: "none" }}
          >
            Associations
          </Tab>
        </TabList>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="blue.500"
          borderRadius="1px"
        />
        <TabPanels>
          <TabPanel>
            <AddressDetailsFormCustomer
              addressLine1={customerAddressData.addressLine1}
              addressLine2={customerAddressData.addressLine2}
              city={customerAddressData.city}
              state={customerAddressData.state}
              pinCode={customerAddressData.pinCode}
              countryCode={customerAddressData.countryCode}
              handleChangeAddressInputs={handleChangeAddressInputs}
            />
          </TabPanel>
          <TabPanel px="0">
            {!!customerProfileData?.supplierAssociations?.length &&
              customerProfileData?.supplierAssociations?.map(
                (
                  supplier: SupplierAssociation,
                  index: Key | number | undefined
                ) => {
                  return (
                    <SupplierAssociationInfo
                      key={index}
                      indexNo={index}
                      supplierId={supplier.supplierId}
                      supplierBranchId={supplier.supplierBranchId}
                      supplierCode={supplier.supplierCode}
                      customerType={supplier.customerType}
                      discount={supplier.discount}
                      onUpdateSupplierAssociation={
                        handleUpdateSupplierAssociation
                      }
                      onDeleteSupplierAssociation={
                        handleDeleteSupplierAssociation
                      }
                      availableSuppliers={availableSuppliers}
                    />
                  );
                }
              )}
            <HStack
              mt={
                !!customerProfileData?.supplierAssociations?.length ? "4" : "0"
              }
              justifyContent={"space-between"}
            >
              {!!customerProfileData?.supplierAssociations?.length ? (
                <Box />
              ) : (
                <Text fontSize={"sm"} fontWeight={"bold"}>
                  No Supplier Associations
                </Text>
              )}
              <Button
                _hover={{ backgroundColor: "#e9ecef !important" }}
                _focus={{ bg: "none", boxShadow: "none" }}
                size="sm"
                bg="none"
                color={"green.400"}
                onClick={handleAddSupplierAssociation}
              >
                + Add
              </Button>
            </HStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageScaffold>
  );
};

export default AddNewCustomer;
