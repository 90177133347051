import { ChangeEvent, useMemo } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  Select,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useGetAllSuppliers } from "../queryHooks";
import { messages } from "../constants";
import { SupplierAssociation } from "../types";
import GaraazAlertDialog from "../../../components/GaraazAlertDialog";
import { secondaryColors } from "../../../utils/designSystem";
import GaraazFormLabel from "../../../components/GarrazFormLabel";
import useNotification from "../../../utils/useNotification";

type associationProps = {
  indexNo?: number | any | undefined;
  onUpdateSupplierAssociation: (
    index: number,
    field: string,
    value: string
  ) => void;
  onDeleteSupplierAssociation: (index: number, supplierId: string) => void;
  availableSuppliers: any;
};

type SupplierAssociationInfoType = SupplierAssociation & associationProps;

const SupplierAssociationInfo = (props: SupplierAssociationInfoType) => {
  const { data: allSuppliers } = useGetAllSuppliers();
  const toast = useNotification();

  const {
    isOpen: isOpenDeleteSupplierAssociationAlert,
    onOpen: onOpenDeleteSupplierAssociationAlert,
    onClose: onCloseDeleteSupplierAssociationAlert,
  } = useDisclosure();

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (name === "supplierId") {
      props.onUpdateSupplierAssociation(props.indexNo, name, value);
      props.onUpdateSupplierAssociation(props.indexNo, "supplierBranchId", "");
    } else {
      props.onUpdateSupplierAssociation(props.indexNo, name, value);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    props.onUpdateSupplierAssociation(props.indexNo, name, value);
  };

  const findSupplierAndBranchName = useMemo(() => {
    const supplier = allSuppliers?.find(
      (supplier: any) => supplier._id === props.supplierId
    );
    const branch = supplier?.branches.find(
      (branch: any) => branch._id === props.supplierBranchId
    );

    return {
      supplierName: supplier?.businessName || "",
      branchName: branch?.branchName || "",
    };
  }, [allSuppliers, props.supplierId, props.supplierBranchId]);

  function getSupplierBusinessName(supplierId: string) {
    const supplier = allSuppliers.find(
      (supplier: any) => supplier._id === supplierId
    );
    return supplier ? supplier.businessName : "";
  }

  function getSupplierBranches(supplierId: string) {
    const supplier = allSuppliers.find(
      (supplier: any) => supplier._id === supplierId
    );
    return supplier ? supplier.branches : [];
  }

  return (
    <Box
      borderRadius={"lg"}
      display={"flex"}
      mt="5"
      _first={{ marginTop: "0px" }}
    >
      <Text mr="3">{parseInt(props.indexNo) + 1}.</Text>
      <Box
        borderRadius={"lg"}
        flex={1}
        display={"flex"}
        flexDirection={"column"}
        border={"1px solid lightgrey"}
        mt="5"
        padding={"10px"}
        backgroundColor={`${secondaryColors.secondary_100}`}
        _first={{ marginTop: "0px" }}
      >
        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl width={"25%"} flex={1}>
            <GaraazFormLabel text={messages.supplierName} isRequired={true} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl>
              <Select
                bg="white"
                name="supplierId"
                placeholder={`Select ${messages.supplierName}`}
                onChange={handleSelectChange}
                value={props.supplierId}
                cursor={"pointer"}
              >
                {allSuppliers?.map((supplier: any) => {
                  const isAvailable = props.availableSuppliers.some(
                    (availableSupplier: any) =>
                      availableSupplier._id === supplier._id
                  );
                  if (isAvailable) {
                    return (
                      <option
                        style={{ cursor: "pointer" }}
                        key={supplier._id}
                        value={supplier._id}
                        selected={supplier._id === props.supplierId}
                      >
                        {getSupplierBusinessName(supplier._id)}
                      </option>
                    );
                  } else {
                    return (
                      <option
                        key={supplier._id}
                        style={{ cursor: "pointer" }}
                        value={supplier._id}
                        disabled
                      >
                        {supplier.businessName}
                      </option>
                    );
                  }
                })}
              </Select>
            </FormControl>
          </Flex>
        </Flex>
        {!!getSupplierBranches(props.supplierId).length && (
          <Flex
            alignItems="center"
            justify="space-between"
            mb={4}
            width={"70%"}
          >
            <FormControl width={"25%"} flex={1}>
              <GaraazFormLabel text={messages.branchName} isRequired={false} />
            </FormControl>
            <Flex width={"75%"}>
              <FormControl>
                <Select
                  bg="white"
                  name="supplierBranchId"
                  placeholder={`Select ${messages.branchName}`}
                  disabled={!props.supplierId}
                  onChange={handleSelectChange}
                  cursor={"pointer"}
                >
                  {props.supplierId &&
                    getSupplierBranches(props.supplierId).map((branch: any) => (
                      <option
                        style={{ cursor: "pointer" }}
                        selected={props.supplierBranchId === branch?._id}
                        key={branch._id}
                        value={branch._id}
                      >
                        {branch.branchName}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </Flex>
          </Flex>
        )}

        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl width={"25%"} flex={1}>
            <GaraazFormLabel text={messages.supplierCode} isRequired={false} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl>
              <Input
                bg="white"
                name="supplierCode"
                type="text"
                isRequired={false}
                isDisabled={!props.supplierId}
                value={props.supplierCode ?? ""}
                onChange={handleInputChange}
                placeholder={messages.supplierCode}
              />
            </FormControl>
          </Flex>
        </Flex>

        {props.supplierId === "62bbfb934de9282bc818d3a1" && (
          <>
            <Flex
              alignItems="center"
              justify="space-between"
              mb={4}
              width={"70%"}
            >
              <FormControl width={"25%"} flex={1}>
                <GaraazFormLabel
                  text={messages.customerType}
                  isRequired={false}
                />
              </FormControl>
              <Flex width={"75%"}>
                <FormControl>
                  <Select
                    bg="white"
                    name="customerType"
                    placeholder={`Select ${messages.customerType}`}
                    onChange={handleSelectChange}
                    value={props.customerType ?? ""}
                    isDisabled={!props.supplierId}
                    cursor={"pointer"}
                  >
                    <option value="Cash">Cash</option>
                    <option value="Credit">Credit</option>
                  </Select>
                </FormControl>
              </Flex>
            </Flex>
            <Flex
              alignItems="center"
              justify="space-between"
              mb={4}
              width={"70%"}
            >
              <FormControl width={"25%"} flex={1}>
                <GaraazFormLabel text={messages.discount} isRequired={false} />
              </FormControl>
              <Flex width={"75%"}>
                <FormControl>
                  <Input
                    bg="white"
                    name="discount"
                    type="number"
                    isRequired={false}
                    isDisabled={!props.supplierId}
                    value={props.discount ?? ""}
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      if (value >= 0 && value <= 100) {
                        handleInputChange(e);
                      } else {
                        toast({
                          description:
                            "Discount must be between 0 and 100",
                          status: "warning",
                        });
                      }
                    }}
                    placeholder={messages.discount}
                  />
                </FormControl>
              </Flex>
            </Flex>
          </>
        )}

        <Button
          size="sm"
          color={"red.400"}
          bg="none"
          aria-label="Remove associations"
          ml={"auto"}
          onClick={() => onOpenDeleteSupplierAssociationAlert()}
          _hover={{ backgroundColor: "#e9ecef !important" }}
          _focus={{ bg: "none", boxShadow: "none" }}
        >
          Delete
        </Button>
      </Box>
      <GaraazAlertDialog
        isOpen={isOpenDeleteSupplierAssociationAlert}
        onClose={onCloseDeleteSupplierAssociationAlert}
        submitBtnText="Delete"
        headerText={"Are you sure ?"}
        bodyText={`You are deleting Supplier Association`}
        branchName={`${findSupplierAndBranchName?.supplierName}${
          findSupplierAndBranchName?.branchName
            ? `-${findSupplierAndBranchName?.branchName}`
            : ""
        }`}
        onSubmitButtonClick={() => {
          props.onDeleteSupplierAssociation(props.indexNo, props.supplierId);
          onCloseDeleteSupplierAssociationAlert();
        }}
      />
    </Box>
  );
};

export default SupplierAssociationInfo;
